const initialState = {
  currentLanguage: 'ua',
  currentLanguageIndex: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        currentLanguage: action.language,
        currentLanguageIndex: action.index
      }

    default:
      return state
  }
}
