module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"menu","short_name":"menu","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/icons/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"de6c1d0a5750f5dc40d9ecfa8eef27f3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"facebook":{"appId":"3210797015631406"},"openGraph":{"type":"website","locale":"en_IE","url":"https://menu.retbar13.com/","site_name":"Menu Restbar13","title":"Menu","description":"Menu Restbar13","images":[{"url":"https://menu.restbar13.com/images/facebook-cover.png"}]},"twitter":{"cardType":"summary_large_image"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
