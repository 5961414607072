import { combineReducers } from 'redux'
import language from './language'
import categories from './categories'
import menuList from './menuList'

export default combineReducers({
  language,
  categories,
  menuList
})
