const initialState = {
  menuListScrollStarted: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MENU_LIST_STARTED':
      return {
        ...state,
        menuListScrollStarted: action.value
      }

    default:
      return state
  }
}
