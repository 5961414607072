const initialState = {
  categoriesOpened: false,
  listScrollStarted: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CATEGORIES_OPENED':
      return {
        ...state,
        categoriesOpened: action.value
      }

    case 'SET_LIST_SCROLL_STARTED':
      return {
        ...state,
        listScrollStarted: action.value
      }

    default:
      return state
  }
}
